<template>
  <el-main>
    <el-button type="primary" size="small" @click="startAddGoods">选择商品</el-button>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="商品信息：">
        <el-input v-model="goods_info" size="small" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getWholeSaleList('search')">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch()">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <div class="showAllList">
      <div class="allTh bd">
        <div>排序</div>
        <div>商品信息</div>
        <div class="other">价格</div>
        <div class="other">起批量</div>
        <div class="other">批发价格</div>
        <div class="other">批发库存</div>
        <div class="other">操作</div>
      </div>
      <No v-if="!list.length"></No>
      <div class="allTbody" v-else>
        <div class="listItem" v-for="(item, index) in list" :key="index">
          <div class="fatherInfo allTh" @click="showSpec(item)">
            <div>
              <i
                :class="['el-icon-caret-right', item.show_spec ? 'ro90' : '']"
                v-if="item.is_spec"
              ></i>
              <el-input
                @blur="updateRank(item)"
                type="number"
                size="small"
                v-model="item.sort"
              ></el-input>
            </div>
            <div class="itemInfo">
              <img :src="item.goods_picture" alt="" />
              <div class="title">{{ item.goods_name }}</div>
            </div>
            <div class="other">{{ item.goods_price }}</div>
            <div class="other" v-if="item.is_spec"></div>
            <div class="other" v-else>{{ item.batch_num }}</div>
            <div class="other" v-if="item.is_spec"></div>
            <div class="other" v-else-if="!item.is_spec && !item.updateFlag">
              {{ item.batch_price }}
            </div>
            <div class="other" v-else>
              <el-input
                @blur="completeUpdate(item)"
                v-model="item.updatePrice"
                v-focus
                size="small"
              ></el-input>
            </div>
            <div class="other" v-if="item.is_spec"></div>
            <div class="other" v-else>{{ item.batch_total }}</div>
            <div class="other" v-if="!item.is_spec">
              <el-button type="text" :disabled="item.updateFlag" @click.stop="updatePrice(item)"
                >修改价格</el-button
              >
              <el-button type="text" @click="deleteGoods(item)">删除</el-button>
            </div>
            <div class="operation other" v-else></div>
          </div>
          <div
            class="isSpec"
            v-if="item.is_spec"
            :style="{ '--height': item.show_spec ? item.goods_spec.length * 84 + 'px' : '0px' }"
          >
            <div
              class="spec_item allTh"
              v-for="(spec_item, spec_index) in item.goods_spec"
              :key="spec_index"
            >
              <div></div>
              <div class="spec_info">
                <img :src="spec_item.picture" alt="" />
                <div class="title">{{ spec_item.title }}</div>
              </div>
              <div class="other">{{ spec_item.price }}</div>
              <div class="other">{{ spec_item.batch_num }}</div>
              <div class="other" v-if="!spec_item.updateFlag">{{ spec_item.batch_price }}</div>
              <div class="other" v-else>
                <el-input
                  @blur="completeUpdate(item, 1, spec_item)"
                  v-focus
                  v-model="spec_item.updatePrice"
                  size="small"
                ></el-input>
              </div>
              <div class="other">{{ spec_item.batch_total }}</div>
              <div class="other">
                <el-button
                  type="text"
                  :disabled="spec_item.updateFlag"
                  @click.stop="updatePrice(spec_item)"
                  >修改价格</el-button
                >
                <el-button type="text" @click="deleteGoods(item, 1, spec_item)">删除</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="选择商品" :visible.sync="chooseGoddsFlag" width="1250px">
      <div class="chooseGoodsBox">
        <div class="top">
          <el-form class="el-form-search" label-width="100px">
            <el-form-item label="商品信息：">
              <el-input size="small" v-model="goods_name" placeholder="请输入商品名称"></el-input>
            </el-form-item>
            <el-form-item label="商品分类：">
              <el-select v-model="chooseClass" size="small" placeholder="请选择">
                <el-option
                  v-for="item in goodsClassList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
              <el-button type="primary" size="small" @click="searchFnLog">搜索</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="bot">
          <div class="showList">
            <div class="listHead itemRow">
              <div>商品信息</div>
              <div>单价</div>
              <div>起批量</div>
              <div>批发价格</div>
              <div>添加库存</div>
              <div>操作</div>
            </div>
            <No v-if="!storeAllGoods.length" />
            <div v-for="(item, index) in storeAllGoods" :key="index">
              <div class="listItem itemRow" @click="item.is_showSpec = !item.is_showSpec">
                <div class="goodsInfo">
                  <i
                    :class="['el-icon-caret-right', item.is_showSpec ? 'ro90' : '']"
                    v-if="item.is_spec"
                  ></i>
                  <img
                    :style="{ margin: item.is_spec ? ' 0 10px' : '0 10px 0 34px' }"
                    :src="item.goods_picture"
                    alt=""
                  />
                  <div>
                    <p>{{ item.goods_name }}</p>
                    <p v-if="!item.is_spec">
                      库存：{{ item.goods_stock != -1 ? item.goods_stock : '无限库存' }}
                    </p>
                  </div>
                </div>
                <div class="price">￥{{ item.goods_price }}</div>
                <div class="livePrice" v-if="item.is_spec"></div>
                <div class="livePrice" v-else>
                  <el-input
                    :disabled="item.is_batch"
                    v-model="item.batch_num"
                    size="small"
                    type="number"
                    placeholder="请输入起批量"
                  ></el-input>
                </div>
                <div class="livePrice" v-if="item.is_spec"></div>
                <div class="livePrice" v-else>
                  <el-input
                    :disabled="item.is_batch"
                    v-model="item.batch_price"
                    type="number"
                    size="small"
                  ></el-input>
                  元
                </div>
                <div class="livePrice" v-if="item.is_spec"></div>
                <div class="livePrice" v-else>
                  <!-- 批发库存 -->
                  <el-input
                    type="number"
                    :disabled="item.is_batch"
                    v-model="item.total"
                    size="small"
                  ></el-input>
                </div>
                <div class="status" v-if="item.is_spec"></div>
                <div
                  class="status cupo"
                  @click="surePushWholesale(item, 1)"
                  :style="{ color: '#409eff' }"
                  v-else-if="!item.is_batch"
                >
                  选择
                </div>
                <div v-else class="status">已选</div>
              </div>
              <div
                class="manySize"
                :style="{ height: item.is_showSpec ? item.goods_spec.length * 70 + 'px' : '0px' }"
                v-if="item.is_spec"
              >
                <div class="itemRow" v-for="(item1, index1) in item.goods_spec" :key="index1">
                  <div class="goodsInfo">
                    <img :src="item1.picture" alt="" />
                    <div>
                      <p>{{ item1.title }}</p>
                      <p>库存：{{ item1.stock != -1 ? item1.stock : '无限库存' }}</p>
                    </div>
                  </div>
                  <div class="price">{{ item1.price }}</div>
                  <div class="livePrice">
                    <el-input
                      :disabled="item1.is_batch"
                      v-model="item1.batch_num"
                      size="small"
                      placeholder="请输入起批量"
                      type="number"
                    ></el-input>
                  </div>
                  <!-- 起批价格 -->
                  <div class="livePrice">
                    <el-input
                      :disabled="item1.is_batch"
                      v-model="item1.batch_price"
                      size="small"
                      type="number"
                    ></el-input
                    >元
                  </div>
                  <!-- 添加库存 -->
                  <div class="livePrice">
                    <el-input
                      :disabled="item1.is_batch"
                      v-model="item1.total"
                      size="small"
                      type="number"
                    ></el-input>
                  </div>
                  <div
                    class="status cupo"
                    @click="surePushWholesale(item1, 2, item)"
                    :style="{ color: '#409eff' }"
                    v-if="!item1.is_batch"
                  >
                    选择
                  </div>
                  <div class="status" v-else>已选</div>
                </div>
              </div>
            </div>
          </div>
          <Paging
            :total="totalLog"
            :page="pageLog"
            :pageNum="pageNumsLog"
            @updatePageNum="updateDataLog"
          ></Paging>
        </div>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging'
import No from '../../components/no.vue'
export default {
  components: {
    Paging,
    No
  },
  created() {
    this.getWholeSaleList()
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.children[0].focus()
      }
    }
  },
  data() {
    return {
      goods_info: '',
      page: 1,
      rows: 10,
      total: 0,
      list: [],
      chooseGoddsFlag: false,
      goods_name: '',
      chooseClass: 0,
      goodsClassList: [],
      storeAllGoods: [],
      pageLog: 1,
      pageNumsLog: 10,
      totalLog: 0
    }
  },
  methods: {
    clearSearch() {
      this.goods_info = ''
      this.getWholeSaleList('search')
    },
    getWholeSaleList(style) {
      if (style) {
        this.page = 1
      }
      let that = this
      this.$axios
        .post(this.$api.push.wholesale.list, {
          page: this.page,
          rows: this.rows,
          goods_name: this.goods_info
        })
        .then((res) => {
          if (res.code === 0) {
            let { list, total_number } = res.result
            list.map((item, index) => {
              this.$set(item, 'show_spec', true)
              if (item.is_spec) {
                item.goods_spec.map((item1, index1) => {
                  this.$set(item1, 'updateFlag', false)
                  this.$set(item1, 'updatePrice', item1.batch_price)
                })
              } else {
                this.$set(item, 'updateFlag', false)
                this.$set(item, 'updatePrice', item.batch_price)
              }
            })
            that.list = list
            that.total = total_number
          }
        })
    },
    // 开启修改价格
    updatePrice(item) {
      item.updateFlag = true
    },
    // 完成修改价格
    completeUpdate(item, status, sku_item) {
      let obj = {}
      if (status) {
        // 多规格
        obj.id = sku_item.batch_id
        obj.goods_id = item.id
        obj.sku_id = sku_item.id
        obj.pf_price = sku_item.updatePrice
      } else {
        obj.id = item.batch_id
        obj.goods_id = item.id
        obj.pf_price = item.updatePrice
      }
      this.$axios.post(this.$api.push.wholesale.edit, obj).then((res) => {
        if (res.code === 0) {
          this.$message.success('价格修改成功')
          if (status) {
            // 多规格
            sku_item.batch_price = sku_item.updatePrice
          } else {
            item.batch_price = item.updatePrice
          }
        } else {
          this.$message.error(res.msg)
        }
        if (status) {
          // 多规格
          sku_item.updateFlag = false
        } else {
          item.updateFlag = false
        }
      })
    },
    // 修改排序
    updateRank(item) {
      let obj = {
        goods_id: item.id,
        sort: item.sort
      }
      if (item.batch_id) {
        obj.id = item.batch_id
      }
      this.$axios.post(this.$api.push.wholesale.sort, obj).then((res) => {
        if (res.code === 0) {
          this.$message.success('成功修改排序')
        }
      })
    },
    deleteGoods(item, status, sku_item) {
      this.$confirm('此操作将该商品从批发列表中删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let obj = {
            id: item.id
          }
          if (status) {
            obj.sku_id = sku_item.id
          }
          return this.$axios.post(this.$api.push.wholesale.delete, obj)
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('删除成功')
            this.getWholeSaleList()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch(() => {})
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val
      } else {
        this.page = val
      }
      // 重新获取数据
      this.getWholeSaleList()
    },
    updateDataLog(val, status) {
      if (status == 0) {
        this.pageNumsLog = val
      } else {
        this.pageLog = val
      }
      // 重新获取数据
      this.getStoreAllGoods()
    },
    startAddGoods() {
      this.getStoreAllClass()
      this.chooseGoddsFlag = true
      this.getStoreAllGoods()
    },
    // 获取站点商品分类列表
    getStoreAllClass() {
      let that = this
      this.$axios.post(this.$api.goods.goodsClass).then((res) => {
        if (res.code === 0) {
          that.goodsClassList = res.result
          that.goodsClassList.unshift({ id: 0, name: '全部' })
        }
      })
    },
    searchFnLog() {
      this.pageLog = 1
      this.getStoreAllGoods()
    },
    // 获取站点所有商品列表
    getStoreAllGoods() {
      let that = this
      this.$axios
        .post(this.$api.push.wholesale.SameCityGoodsBatch, {
          page: this.pageLog,
          rows: this.pageNumsLog,
          goods_name: this.goods_name,
          classify_id: !this.chooseClass ? null : this.chooseClass,
          is_putaway: 2,
          status: 1
        })
        .then((res) => {
          if (res.code === 0) {
            that.totalLog = res.result.total_number
            that.storeAllGoods = res.result.list
            that.storeAllGoods.map((item) => {
              that.$set(item, 'is_showSpec', true)
              if (!item.is_spec) {
                if (!item.is_batch) {
                  this.$set(item, 'batch_price', item.goods_price)
                  this.$set(item, 'total', item.goods_stock)
                }
              } else {
                item.goods_spec.map((item1) => {
                  if (!item1.is_batch) {
                    this.$set(item1, 'batch_price', item1.price)
                    this.$set(item1, 'total', item1.stock)
                  }
                })
              }
            })
          }
        })
    },
    // 确认将选中的商品推送到直播商品库
    surePushWholesale(row, status, pItem) {
      if (!Number(row.batch_num) || isNaN(Number(row.batch_num))) {
        this.$message.warning('请输入正确的起批量')
        return
      }
      if (row.total < row.batch_num) {
        this.$message.warning('起批量不能大于设置的库存')
        return
      }
      let that = this
      let obj = {
        goods_id: row.id,
        price: row.goods_price,
        total: row.total,
        image: row.goods_picture,
        title: row.goods_name,
        pf_price: row.batch_price,
        batch: row.batch_num
      }
      if (status == 2) {
        obj.goods_id = pItem.id
        obj.sku_id = row.id
        obj.price = row.price
        obj.image = row.picture
        obj.title = pItem.goods_name + ':' + row.title
      }
      this.$axios.post(this.$api.push.wholesale.wholesaleAdd, obj).then((res) => {
        if (res.code == 0) {
          that.$message.success('添加成功')
          that.getWholeSaleList()
          that.chooseGoddsFlag = false
        } else {
          that.$message.error(res.msg)
        }
      })
    },
    //展开多规格商品
    showSpec(item) {
      item.show_spec = !item.show_spec
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  & > .el-button {
    margin-bottom: 10px;
  }
  .info_box {
    display: flex;
    align-items: center;
    padding-left: 30px;
    img {
      width: 50px;
      height: 50px;
    }
    .title {
      max-height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
}
.chooseGoodsBox {
  .bot {
    .showList {
      width: 100%;
      .listHead {
        width: 100%;
        display: flex;
        line-height: 48px;
        background-color: #f8f9fa;
      }
      .itemRow {
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        & > div:first-of-type {
          width: 250px;
          flex-shrink: 0;
          padding: 0 10px;
          i {
            font-size: 24px;
            transition: all 0.3s;
          }
          .ro90 {
            transform: rotate(90deg);
          }
          img {
            width: 40px;
            height: 40px;
          }
          & > div {
            height: 100%;
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 14px 0;
            p {
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        & > div:nth-of-type(2) {
          width: 100px;
          flex-shrink: 0;
        }
        & > div:nth-of-type(5) {
          width: 250px;
          flex-shrink: 0;
        }
        & > div:nth-of-type(3) {
          img {
            width: 60px;
            height: 60px;
          }
          font-size: 12px;
          width: 250px;
          flex-shrink: 0;
        }
        & > div:nth-of-type(4) {
          width: 250px;
          flex-shrink: 0;
          .el-input {
            width: 150px;
          }
        }
        & > div:nth-of-type(6) {
          flex: 1;
          flex-shrink: 0;
        }
      }
      .cupo {
        cursor: pointer;
      }
      .listItem {
        display: flex;
        height: 70px;
        cursor: pointer;
        &:hover {
          background-color: #f5f7fa;
        }

        .el-input {
          width: 150px;
          margin-right: 5px;
        }
      }
      .manySize {
        transition: all 0.3s;
        overflow: hidden;
        .el-input {
          width: 150px;
        }
        .itemRow {
          display: flex;
          height: 70px;
          &:hover {
            background-color: #f5f7fa;
          }
          .el-input {
            margin-right: 5px;
          }
        }
        .goodsInfo {
          padding: 0 10px 0 75px !important;

          img {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.showAllList {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
  .allTh {
    width: 100%;
    display: flex;
    text-align: center;
    line-height: 48px;
    font-size: 14px;
    background-color: #f8f9fa;
    color: #909399;

    & > div:first-child {
      width: 180px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      & > i {
        font-size: 24px;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 0.3s;
      }
      .ro90 {
        transform: translateY(-50%) rotate(90deg);
      }
      .el-input {
        width: 100px;
        border-color: transparent;
        & /deep/ input {
          border-color: transparent;
          text-align: center;
          &:focus {
            border-color: #409eff;
          }
        }
      }
    }
    & > .other {
      flex: 1;
      flex-shrink: 0;
      min-width: 100px;
    }
    & > div:nth-child(2) {
      width: 400px;
      flex-shrink: 0;
    }
  }
  .bd {
    border: 1px solid #ebeef5;
  }
  .allTbody {
    .listItem {
      border-bottom: 1px solid #e4e4e4;
      border-left: 1px solid #e4e4e4;
      border-right: 1px solid #e4e4e4;
      .fatherInfo {
        line-height: 60px;
        background-color: #fff;
        padding: 12px 0;
        &:hover {
          background-color: #f5f7fa;
        }
      }
      .itemInfo {
        display: flex;
        align-items: center;
        padding-left: 100px;

        img {
          width: 60px;
          height: 60px;
          margin-right: 10px;
        }
        .title {
          line-height: normal;
          white-space: normal;
          text-align: left;
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
      .isSpec {
        width: 100%;
        height: var(--height);
        overflow: hidden;
        transition: all 0.3s;
        .spec_item {
          display: flex;
          line-height: 60px;
          padding: 12px 0;
          background-color: #fff;
          &:hover {
            background-color: #f5f7fa;
          }
          .spec_info {
            display: flex;
            align-items: center;
            padding-left: 130px;
          }
          img {
            width: 60px;
            height: 60px;
            margin-right: 10px;
          }
          .title {
            line-height: normal;
            white-space: normal;
            text-align: left;
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }
}
</style>
